import {
    Button,
    Dialog,
    useFirebaseApp,
    useRuntimeEnv,
    useTranslations,
} from "@arsea/component";
import { useEffect, useState } from "react";
import moment from "moment";

const NotificationDialog = () => {

    const { runtimeEnv } = useRuntimeEnv();
    const [showDialog, SetShowDialog] = useState(false);
    const [showOnce, setShowOnce] = useState(false);
    const { subscribeToPushNotification } = useFirebaseApp();

    const [
        NOTIFICATION_TITLE,
        PERMISSION_MESSAGE,
        SHOW_ONCE,
        ACTIVATE_LABEL,
        CLOSE_LABEL
    ] = useTranslations(i18n)

    const handleConfirm = () => {
        window.Notification.requestPermission().then((status) => {
            if (status === "granted") {
                subscribeToPushNotification();
            }

            if(window.Notification?.permission !== "default") {
                SetShowDialog(false);    
                window.localStorage.setItem("show-once-notification-permission", showOnce.toString());
            }

        });
    }

    const handleCancel = () => {
        SetShowDialog(false);    
        window.localStorage.setItem("show-once-notification-permission", showOnce.toString());
    }

    useEffect(() => {
        const alreadyShownToday = moment(window.localStorage.getItem("last-show-dialog")).isSame(moment(), "day")
        
        if(runtimeEnv?.authenticated 
            && window.Notification?.permission !== "granted" 
            && window.localStorage.getItem("show-once-notification-permission") !== "true"
            && !alreadyShownToday
        ) {
            window.localStorage.setItem("last-show-dialog", moment().toISOString());
            setTimeout(() => SetShowDialog(true), 10000)
        }
    }, []);


    return (
        <>
            <Dialog
                data-testid={`notification-permission-dialog`}
                open={showDialog}
                title={NOTIFICATION_TITLE}
                onClose={handleCancel}
            >
                <div className="flex flex-col">
                    <p>
                        {PERMISSION_MESSAGE}
                    </p>
                    <div className="mt-5 flex flex-col">
                        <div className="flex items-center gap-2">
                            <input type="checkbox" id="no-more-show" onChange={(e: any) => {
                                setShowOnce(e.target.checked)
                            }}/>
                            <label htmlFor="no-more-show">{SHOW_ONCE}</label>
                        </div>
                        <div className="flex justify-between mt-4">
                            <Button
                                className="btn-default"
                                onClick={handleCancel}
                            >
                                {CLOSE_LABEL}
                            </Button> 
                            <Button
                                className="btn-primary"
                                onClick={handleConfirm}
                            >
                                {ACTIVATE_LABEL}
                            </Button> 
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

const i18n = [
    "Arsea.Notifications.Label",
    "Arsea.Notification.Permission.Message",
    "Arsea.Notification.ShowOnce.Message",
    "std_activate",
    "std_close"
]

export default NotificationDialog;
